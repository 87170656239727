@use '../../../../styles/_global-variables.scss' as global;
@use '../../../../styles/_portal-variables.scss' as tresplay;

.buttonShow {
    opacity: 1 !important;
}

.loadingIcon {
    background-image: url(/_resources/imatges/loading.svg?v=1.2504.0);
    background-size: 32px 32px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
    opacity: 1;
}

.boto {
    cursor: pointer;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    &.hover {
        .tooltip,
        .tooltiptouch {
            opacity: 1;
        }
        svg {
            opacity: 1;
        }
    }
    svg {
        width: 32px;
        height: 32px;
        pointer-events: none;
        opacity: 0.92;
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
    }
}
.tooltip {
    opacity: 0;
    white-space: nowrap;
    border-radius: 8px;
    pointer-events: none;
    transition: opacity 0.5s;
    font-size: var(--body_s);
    z-index: 1;
}
.tooltipfloat {
    opacity: 0;
    position: absolute;
    white-space: nowrap;
    background: global.$color0;
    color: global.$color90;
    padding: 8px;
    border-radius: 8px;
    pointer-events: none;
    transition: opacity 0.5s;
    font-size: var(--body_s);
    &:before {
        content: '';
        background: global.$color0;
        position: absolute;
    }
    &.right {
        left: calc(100% + 8px);
        top: -1px;
        &:before {
            left: -8px;
            top: calc(50% - 8px);
            width: 9px;
            height: 16px;
            clip-path: polygon(0 50%, 100% 100%, 100% 0);
        }
    }
    &.left {
        right: calc(100% + 8px);
        top: -1px;
        &:before {
            right: -8px;
            top: calc(50% - 8px);
            width: 9px;
            height: 16px;
            clip-path: polygon(100% 50%, 0 0, 0 100%);
        }
    }
    &.top {
        bottom: calc(100% + 8px);
        transform: translateX(calc(-50% + 16px));
        &:before {
            left: calc(50% - 8px);
            bottom: -8px;
            width: 16px;
            height: 9px;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
        }
    }
    &.topright {
        bottom: calc(100% + 8px);
        transform: translateX(calc(-50% + 76px));
        &:before {
            left: calc(50% - 68px);
            bottom: -8px;
            width: 16px;
            height: 9px;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
        }
    }
    &.bottom {
        top: calc(100% + 8px);
        transform: translateX(calc(-50% + 16px));
        &:before {
            left: calc(50% - 8px);
            top: -8px;
            width: 16px;
            height: 9px;
            clip-path: polygon(50% 0, 0 100%, 100% 100%);
        }
    }
}
.tooltiptouch {
    position: fixed;
    bottom: 10px;
    right: 8px;
    background: global.$color70;
    color: global.$color0;
    padding: 18px;
    width: calc(100% - 16px);
    max-width: 460px;
    left: 50%;
    transform: translateX(-50%);
}
