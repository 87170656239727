@use '../../../../styles/_global-variables.scss' as global;
@use '../../../../styles/_portal-variables.scss' as tresplay;

.progress {
    height: 4px;
    border-radius: 2px;
    overflow: hidden;
    background: var(--clr_trans_40);
}
.bar {
    display: block;
    height: 4px;
    width: 0;
    background: var(--clr_portal_100);
}
