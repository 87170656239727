@use '../../../styles/_global-variables.scss' as global;
.pagMesInfo {
    margin: var(--gapRow) auto calc(12px - var(--gapRow)) 0;
}
.clickable {
    pointer-events: none;
}
@media #{global.$VP_ALL_MOBILE} {
    .pagMesInfo {
        margin: calc(var(--gapRow) / 2) auto calc(10px - var(--gapRow)) 0;
    }
}
