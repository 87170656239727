@use '../../../../styles/_global-variables.scss' as global;

.itemMosaic {
    position: relative;
}

.info {
    display: grid;
    position: relative;
    color: #fff;
    overflow: hidden;
    grid-template-areas:
        'barra'
        'text';
    align-items: start;
    grid-template-rows: auto auto 1fr;
    min-height: 90px;

    @media #{global.$VP_ALL_MOBILE} {
        min-height: 0;
    }
}

.despres_fem {
    font-size: var(--body_xs);
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
}
.despres_info {
    margin-top: 5px;
    display: block;
}
.imatge {
    aspect-ratio: 16/9;
    grid-area: foto;
    position: relative;
}
.titol {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: var(--body_m);
    padding-top: 12px;
    grid-area: text;
    line-height: 1.35;
}

.logocanal {
    pointer-events: none;
    margin-bottom: 2px;
    position: absolute;
    bottom: 12px;
    left: 12px;
    height: 25px;
    z-index: 1;
}
.progres {
    width: 100%;
    grid-area: barra;
    margin-top: 8px;
}
.inactiu {
    .msginactiu {
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;
        display: block;
        background: #fff;
        color: #000;
        position: absolute;
        top: 8px;
        right: 10px;
        padding: 3px 5px;
        z-index: 1;
        border-radius: 3px;
    }
    .titol {
        opacity: 0.5;
    }
}

.ico_codi {
    width: 16.67px;
    height: 16.67px;
}

.wrapperItemMosaic {
    button {
        display: none;
    }
}

.descripcio {
    &.descripcioWebview {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
    }

    .iconaInfo {
        width: 22px;
        padding-top: 12px;
    }
}

.codiDurada {
    display: none;

    &.isWebviewActive {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 10px;
    }
}

.entradeta {
    opacity: 0;
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: var(--body_s);
}

.titolPrograma {
    padding-top: 10px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
}
.titolCapitol {
    font-size: 14px;
    line-height: 21px;
}

.ico_next {
    width: 15px;
    margin-right: 5px;
    vertical-align: top;
}
.icona_play {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-content: center;
    opacity: 0;
    border-radius: 50%;

    &:hover {
        background-color: var(--clr_portal_100);
        .play {
            opacity: 1;
        }
    }

    @media #{global.$VP_ALL_MOBILE} {
        opacity: 1;
    }
}

.play {
    width: 23px;
    opacity: 0.8;
}

@media (hover) {
    .directeHover:hover {
        overflow: visible;

        .icona_play {
            opacity: 1;
        }

        .info {
            padding: 0 16px 16px;
            grid-template-areas:
                'text'
                'barra';
        }

        .codiDurada {
            display: flex;
            align-items: center;
            gap: 10px;
            padding-top: 16px;
            opacity: 1;
        }

        .wrapperItemMosaic {
            transition: margin-top 0.2s ease-in-out, margin-left 0.2s ease-in-out, margin-right 0.2s ease-in-out;
            position: relative;
            margin: -36px -16px -100%;
            background-color: var(--clr_trans_90);
            border: 1px solid var(--clr_trans_80);
            z-index: 1;
            box-shadow: 0px 0px 14px 5px rgba(0, 0, 0, 0.75);
            button {
                display: block;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
        .titol {
            font-size: var(--body_l);
            -webkit-line-clamp: 3;
        }

        .durada {
            font-size: var(--body_s);
        }

        .entradeta {
            -webkit-line-clamp: 3;
            transition-property: opacity;
            transition-delay: 0.2s;
            transition-duration: 0.2s;
            opacity: 1;
            line-height: 1.35;
            padding-top: 16px;
        }
    }
}

@media (hover) {
    @media (min-width: 570px) and (max-width: 926px) {
        .itemMosaic:nth-child(2n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .itemMosaic:nth-child(2n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 927px) and (max-width: 1210px) {
        .itemMosaic:nth-child(3n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .itemMosaic:nth-child(3n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 1211px) and (max-width: 1541px) {
        .itemMosaic:nth-child(4n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .itemMosaic:nth-child(4n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 1542px) and (max-width: 1825px) {
        .itemMosaic:nth-child(5n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .itemMosaic:nth-child(5n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}

@media (hover) {
    @media (min-width: 1826px) {
        .itemMosaic:nth-child(6n):hover {
            .wrapperItemMosaic {
                margin-right: 0;
            }
        }
        .itemMosaic:nth-child(6n + 1):hover {
            .wrapperItemMosaic {
                margin-left: 0;
            }
        }
    }
}
