@use '../../../styles/_global-variables.scss' as global;

.contenidorMosaic {
    width: 100%;
    position: relative;
}

.mosaic {
    display: grid;
    grid-template-columns: 1fr;
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
    gap: 16px;
    row-gap: 25px; // 48px computed entradeta + min-height
    margin-bottom: var(--gapRow);

    &.columns-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.columns-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &.columns-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &.columns-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    &.columns-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}
.directesHoritzontal {
    .columns-2 {
        gap: 75px;
        @media #{global.$VP_TP} {
            gap: 16px;
        }
        @media #{global.$VP_ALL_MOBILE} {
            gap: 16px;
        }
        @media #{global.$VP_BEYOND_TP} {
            gap: 75px;
            row-gap: 25px;
        }
        @media (min-width: 1920px) {
            row-gap: 32px;
            column-gap: 150px;
        }
    }
}
.directesHoritzontalLarge {
    .mosaic {
        @media (min-width: 1920px) {
            row-gap: 32px;
        }
    }
    .mosaic.bdtv {
        @media #{global.$VP_TP} {
            padding-right: 8px;
        }
        @media #{global.$VP_BEYOND_TP} {
            padding-right: 37.5px;
        }
        @media (min-width: 1920px) {
            padding-right: 75px;
        }
    }
}
.az {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 0 0 calc(var(--gapRow) - 72px) 0;
}
.coleccions {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.potserBusques,
.similarsWebview {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.infoTitol {
    font-weight: 300;
    font-size: var(--headline_xs);
    line-height: 1.4;
    text-transform: uppercase;
    padding: 0;
    pointer-events: all;
    max-width: 2144px;
    margin: 0 auto 16px;
    a:hover {
        border-bottom: 1px solid;
    }
}

.contenidorTitol {
    line-height: 1.4;
    padding-bottom: 16px;

    .linktitol {
        margin-bottom: 5px;
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }
}

.titol {
    font-weight: 300;
    text-transform: uppercase;
    font-size: var(--headline_xs);
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom: 1px solid currentColor;
    }
}

.btnMoreItemsContent {
    text-align: center;
    margin-bottom: calc(8px + var(--gapRow));
    margin-top: calc(8px - var(--gapRow));
}

// 570 - 767 (2 elements)
@media (min-width: 570px) and (max-width: 767px) {
    .mosaic {
        &.columns-5,
        &.columns-6 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    .default,
    .coleccions {
        grid-template-columns: repeat(2, 1fr);
    }
}
// 768 - 1023 (2 elements)
@media (min-width: 768px) and (max-width: 1023px) {
    .mosaic {
        &.columns-6 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    .default,
    .coleccions {
        grid-template-columns: repeat(3, 1fr);
    }
}
// 1024 - 1210  (4 elements)
@media (min-width: 1024px) and (max-width: 1210px) {
    .mosaic {
        &.columns-6 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    .default,
    .coleccions {
        grid-template-columns: repeat(4, 1fr);
    }
}

// 1211 -  (4 elements)
@media (min-width: 1211px) {
    .default,
    .coleccions {
        grid-template-columns: repeat(4, 1fr);
    }
}

// 768 - 1023  (4 elements)
@media #{global.$VP_TP} {
    .potserBusques,
    .similarsWebview {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1440px) {
    .coleccions {
        grid-template-columns: repeat(6, 1fr);
    }
}

// < 767 (2 elements)
@media #{global.$VP_ALL_MOBILE} {
    .mosaic {
        &.similarsWebview,
        &.potserBusques,
        &.columns-2,
        &.columns-3,
        &.columns-4,
        &.columns-5,
        &.columns-6 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}

@media (max-width: 569px) {
    .mosaic {
        &.columns-2,
        &.columns-3,
        &.columns-4,
        &.columns-5,
        &.columns-6 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        grid-row-gap: 32px;
        row-gap: 32px;
        &.az {
            grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
        }
    }
    .coleccions {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

// 2560- (5 elements)
@media (min-width: 2560px) {
    .mosaic {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        max-width: 2140px;
        margin-inline: auto;
    }
    .directesHoritzontalLarge {
        .mosaic {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
    .potserBusques,
    .similarsWebview,
    .coleccions {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}
