@use '../../../../styles/_global-variables.scss' as global;

.itemMosaic {
    position: relative;
}

.info {
    position: relative;
    color: #fff;
    min-height: 90px;

    @media #{global.$VP_ALL_MOBILE} {
        min-height: 0;
    }
}

.despres_fem {
    font-size: var(--body_xs);
    transition-property: opacity;
    transition-delay: 0s;
    transition-duration: 0s;
}
.despres_info {
    margin-top: 5px;
    display: block;
}
.imatge {
    aspect-ratio: 16/9;
    grid-area: foto;
    position: relative;
    background-color: #333;
}
.titol {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: var(--headline_xs);
    padding-top: 8px;
    line-height: 1.35;
    padding-bottom: 8px;
}

.logocanal {
    pointer-events: none;
    margin-bottom: 2px;
    position: absolute;
    bottom: 12px;
    left: 12px;
    height: 38px;
    z-index: 1;
}
.cr {
    height: 41px;
}
.logoExclusiuInternet {
    bottom: 24px;
}
.progres {
    width: 100%;
    /*position: absolute;
    bottom: 0;*/
}
.inactiu {
    .msginactiu {
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;
        display: block;
        background: #fff;
        color: #000;
        position: absolute;
        top: 8px;
        right: 10px;
        padding: 3px 5px;
        z-index: 1;
        border-radius: 3px;
    }
    .titol {
        opacity: 0.5;
    }
}

.ico_codi {
    width: 16.67px;
    height: 16.67px;
}

.wrapperItemMosaic {
    button {
        display: none;
    }
}

.descripcio {
    width: 100%;
    &.descripcioWebview {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
    }

    .iconaInfo {
        width: 22px;
        padding-top: 12px;
    }
}

.codiDurada {
    width: 100%;
    &.isWebviewActive {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 10px;
    }
}

.titolPrograma {
    padding-top: 10px;
    font-size: var(--body_m);
    line-height: 25px;
    font-weight: 700;
}
.titolCapitol {
    font-size: var(--body_s);
    line-height: 21px;
}

.ico_next {
    width: 15px;
    margin-right: 5px;
    vertical-align: top;
}
.horitzontal {
    margin-bottom: 32px;
    .wrapperItemMosaic {
        display: flex;
    }
    .wrapperWeb {
        flex-direction: row-reverse;
    }
    .enllacImatge {
        padding-right: 8px;
        width: 55%;
        display: block;
    }
    .info {
        display: flex;
        flex-direction: column;
        width: 45%;
        padding-left: 8px;
        justify-content: space-between;
    }
}
.spanIcon {
    width: 32px;
    height: 32px;
    background-color: rgba(17, 17, 17, 0.5);
    border-radius: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    left: 5px;
}
.auricularsIcon {
    width: 20px;
    height: 20px;
}
.noDisponible:before {
    content: 'NO DISPONIBLE';
    text-transform: uppercase;
    font-size: var(--body_s);
    font-weight: 500;
    display: block;
    background: #fff;
    color: #000;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px 8px;
    z-index: 1;
    border-radius: 3px;
}
.bdtv {
    height: 24px;
}
.imatge:after {
    content: '';
    display: block;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.9) 99.68%);
}
.icona_play {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    opacity: 0;
    border-radius: 50%;
    background-color: var(--clr_portal_100);
    line-height: 0;
}
@media (hover) {
    .itemMosaic:hover {
        .icona_play {
            opacity: 1;
        }
    }
}

@media #{global.$VP_REGULAR_AND_TL} {
    .horitzontal {
        .enllacImatge {
            width: 48%;
        }
        .info {
            width: 52%;
        }
    }
    .titol {
        padding-top: 0;
        padding-bottom: 6px;
        font-size: var(--body_l);
    }
    .capitol {
        font-size: var(--body_s);
    }

    .logocanal {
        height: 24px;
    }
    .logoExclusiuInternet {
        bottom: 18px;
    }
    .spanIcon {
        width: 24px;
        height: 24px;
    }
    .auricularsIcon {
        width: 16px;
        height: 16px;
    }
    .bdtv {
        height: 14px;
    }
}
@media #{global.$VP_TP} {
    .horitzontal {
        .enllacImatge {
            width: 50%;
        }
        .info {
            width: 50%;
        }
    }
    .titol {
        line-height: 1.1;
        padding-top: 0;
        font-size: var(--body_m);
        -webkit-line-clamp: 5;
        padding-bottom: 4px;
    }
    .capitol {
        font-size: var(--body_xs);
    }
    .logocanal {
        height: 21px;
    }
    .logoExclusiuInternet {
        bottom: 18px;
    }
    .spanIcon {
        width: 24px;
        height: 24px;
    }
    .auricularsIcon {
        width: 16px;
        height: 16px;
    }
    .bdtv {
        height: 12px;
    }
}
@media #{global.$VP_ALL_MOBILE} {
    .horitzontal {
        .enllacImatge {
            width: 50%;
        }
        .info {
            width: 50%;
        }
    }
    .responsive {
        margin-bottom: 0;
        .wrapperItemMosaic {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        .info,
        .enllacImatge {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .titol {
        padding-top: 0;
        font-size: var(--body_m);
        -webkit-line-clamp: 5;
    }
    .cos {
        order: 2;
    }
    .descripcio {
        min-height: 72px;
    }
    .capitol {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: var(--body_s);
    }
    .progres {
        margin-top: 8px;
        margin-bottom: 10px;
        order: 1;
        width: 100%;
    }
    .logocanal {
        pointer-events: none;
        position: relative;
        bottom: auto;
        margin-top: -28px;
        margin-bottom: 8px;
        left: 8px;
        height: 23px;
        order: 2;
    }
    .info {
        order: 1;
    }
    .enllacImatge {
        order: 3;
    }
    .logoExclusiuInternet {
        bottom: 16px;
    }
    .spanIcon {
        width: 24px;
        height: 24px;
    }
    .auricularsIcon {
        width: 16px;
        height: 16px;
    }
    .noDisponible:before {
        font-size: 9px;
        padding: 4px 6px;
    }
    .imatge:after {
        height: 30px;
    }
    .bdtv {
        height: 12px;
    }
    .wrapperRadio .capitol {
        display: none;
    }
}
@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .logocanal {
        height: 38px;
    }
    .bdtv {
        height: 32px;
    }
    .horitzontal {
        .enllacImatge {
            width: 52%;
        }
        .info {
            width: 48%;
        }
    }
}

//FIX SAFARI
@supports not (aspect-ratio: 16 / 9) {
    .horitzontal .imatge {
        padding-top: calc(100% / 16 * 9);
    }
}
